import styles from './footer.module.scss';
import { getConsentType } from '../../utils/consent-utils.js';
import { version } from '../../../package';

function Footer({ brand = '' }) {
    const consentType = getConsentType();

    const Links = () => {
        if (brand === 'Complex') {
            return <span><a href="https://www.complex.com/privacy">Privacy Policy</a></span>;
        } else if (brand === 'HuffPost') {
            return <span><a href="https://www.huffpost.com/static/privacy-policy">Privacy Policy</a></span>;
        } else {
            return <span>
                <a href="https://www.buzzfeed.com/about/useragreement">User Agreement</a>
                <span>•</span>
                <a href="https://www.buzzfeed.com/about/privacy">Privacy Policy</a>
            </span>;
        }
    }

    return (
        <footer className={styles.footer}>
            <div>
                <Links/>
            </div>
            { consentType === 'ccpa' &&
        <button
            className={`${styles.ccpa_button} ot-sdk-show-settings`}
            id='ot-sdk-btn'
        >
          Do Not Sell or Share My Personal Information
        </button>
            }
            { consentType === 'gdpr' &&
        <button
            className={`${styles.gdpr_button} ot-sdk-show-settings`}
            id='ot-sdk-btn'
        >
          Update Consent
        </button>
            }
            <br/>
            <span className={styles.version_text}>v{version}</span>
        </footer>
    );
}

export default Footer;
