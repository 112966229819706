export const isServer = () => typeof window === 'undefined';

/**
  * Gets cookie value by name.
  * @param {String} name - cookie name.
  * @param {String} defaultValue - value to return if cookie is not defined.
  * @returns {String} - value of the specified cookie or default.
 */
export const getCookie = (name, defaultValue = null) => {
    const regex = new RegExp(`\\b${name}=([^;]+)`); // https://regex101.com/r/5PbWpy/4/tests

    const match = document.cookie.match(regex);
    return match && match[1] ? match[1] : defaultValue;
};
