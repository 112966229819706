import getConfig from 'next/config';

const {
    CLUSTER,
    NODE_ENV,
    RELEASE,
    abeagle_host,
    bf_url,
    sentry_public_dsn,
    video_player_url
} = getConfig().publicRuntimeConfig;

export {
    CLUSTER,
    NODE_ENV,
    RELEASE,
    abeagle_host as ABEAGLE_HOST,
    bf_url as BF_URL,
    video_player_url as VIDEO_PLAYER_URL,
    sentry_public_dsn as SENTRY_PUBLIC_DSN
};
