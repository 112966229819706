import React from 'react';
import PropTypes from 'prop-types';

let width = 20;
let height = 20;
const spacing = 5;
const duration = 1000;
let rectWH = [0, 20, 20, 20, 0];
let x = [10, 0, 0, 0, 10];
let y = [10, 0, 0, 0, 10];
const delay = 200;
const shapeCount = 3;

const animationAttrs = (i, xVal, shape) => {
  const rectR = [0, 3, 3, 0];
  if (shape === 'circle') {
    return {
      __html: `<animate attributeName="r" values="${rectR.join(';')}" begin="${i * delay}ms" dur="${duration}ms" repeatCount="indefinite"/>
                <animate attributeName="cx" values="${(xVal).join(';')}" begin="${i * delay}ms" dur="${duration}ms" repeatCount="indefinite"/>`,
    };
  } else {
    // these tags are not supported by React so we have to set them as a string
    return {
      __html: `<animate attributeName="width" values="${rectWH.join(';')}" begin="${i * delay}ms" dur="${duration}ms" repeatCount="indefinite"/>
                <animate attributeName="height" values="${rectWH.join(';')}" begin="${i * delay}ms" dur="${duration}ms" repeatCount="indefinite"/>
                <animate attributeName="x" values="${xVal.join(';')}" begin="${i * delay}ms" dur="${duration}ms" repeatCount="indefinite"/>
                <animate attributeName="y" values="${y.join(';')}" begin="${i * delay}ms" dur="${duration}ms" repeatCount="indefinite"/>`,
    };
  }
};

const buildXVal = (xPos, num) => num + xPos;

const colors = {
  black: 'rgb(0, 0, 0)',
  red: 'rgb(238, 51, 34)',
};

const Loader = (props) => {
  width = props.size;
  height = props.size;
  rectWH = [0, props.size, props.size, props.size, 0];
  x = [(props.size / 2), 0, 0, 0, (props.size / 2)];
  y = [(props.size / 2), 0, 0, 0, (props.size / 2)];
  const r = (props.size / 2);
  const shapes = [];
  for (let i = 0; i < shapeCount; i++) {
    let xPos = i * (width + spacing);
    if (props.shape === 'circle') {
      xPos += r;
    }
    const xVal = x.map(buildXVal.bind(null, xPos));
    const shapeProps = {
      r,
      cx: xPos,
      cy: r,
      width,
      height,
      x: xPos,
      y: 0,
      dangerouslySetInnerHTML: animationAttrs(i, xVal, props.shape),
      key: i,
      style: {
        fill: colors[props.color],
      },
    };
    if (props.shape === 'circle') {
      shapes.push(<circle {...shapeProps} />);
    } else {
      shapes.push(<rect {...shapeProps} />);
    }
  }

  const style = {
    display: 'block',
    margin: '30px auto',
    textAlign: 'center',
  };

  return (
    <div className="loader" style={style}>
      <svg width="70" height="20">{shapes}</svg>
    </div>
  );
};

Loader.propTypes = {
  color: PropTypes.string,
  shape: PropTypes.string,
  size: PropTypes.number,
};

Loader.defaultProps = {
  color: 'red',
  shape: 'rect',
  size: 20,
};

export default Loader;
