import { createClientEvent } from '@buzzfeed/client-event-tracking';
import {
    ab_test,
    addressability,
    external_link,
    impression,
    pageview,
    time_spent,
    videoview,
} from '@buzzfeed/client-event-tracking/events';
import {
    createClientImpressionHandler,
    createClientTimeSpentHandler,
} from '@buzzfeed/client-event-tracking/handlers';
import { CLUSTER } from '../../constants';

const sendClientEvent = createClientEvent({ env: CLUSTER, source: 'dotbio'});

export const bioCommonLayerData = account => () => ({
    context_page_id: account.id,
    context_page_type: 'list',
    destination: 'dotbio',
    page_edition: 'en-us'
});

const createClientEventWithAccount = (event_config) =>
    (account, ...layers) => {
        sendClientEvent(event_config, bioCommonLayerData(account), ...layers);
    };

export const trackClientAddressability = createClientEventWithAccount(addressability);

export const trackClientPageview = createClientEventWithAccount(pageview);

export const trackClientTimeSpent = createClientEventWithAccount(time_spent);

export const trackClientExternalLink = createClientEventWithAccount(external_link);

export const trackClientImpression = createClientEventWithAccount(impression);

export const trackClientExperimentActive = createClientEventWithAccount(ab_test);

export const trackClientVideoView = createClientEventWithAccount(videoview);

export const attachClientImpressionHandler = createClientImpressionHandler(trackClientImpression);

export const attachClientTimeSpentHandler = createClientTimeSpentHandler(
  ({ layers }, ...additional_args) => {
    trackClientTimeSpent(...additional_args, ...layers);
  }
);
