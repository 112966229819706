import { useEffect } from 'react';
import { trackClientAddressability } from '../../analytics/client-event-tracking';
import cookies from '@buzzfeed/bf-utils/lib/cookies';
import storage from '@buzzfeed/bf-utils/lib/storage';
import { urlQueryToObject } from '@buzzfeed/bf-utils/lib/query-string';

export function useAddressabilityTracking(account) {
  useEffect(() => {
    const hem = cookies.get('hem');
    const user = cookies.get('bf2-b_info');
    const queryObject = urlQueryToObject(window.location.search);

    let addressableSource = [];
    if (queryObject['email_hash'] || storage.sessionGet('newsletterAddressable')) {
      storage.sessionSet({ key: 'newsletterAddressable', value: 'true' });
      addressableSource.push('newsletter');
    }
    if (user) {
      addressableSource.push('auth');
    }

    trackClientAddressability(account, {
      is_addressable: !!hem,
      addressable_source: addressableSource,
      addressable_partner: []
    });
  }, [account]);
}
