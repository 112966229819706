
const brands = [
    {
        key: 'BuzzFeed',
        favicon: 'http://www.buzzfeed.com/static-assets/img/favicon.5a0c77a8815cfcc67c710199054a55c6.ico',
        url: 'https://buzzfeed.bio',
        title: `Biofeed by BuzzFeed`,
        description: `Explore your favorite BuzzFeed posts from Instagram and TikTok.`,
        header: `Click to explore your favorite BuzzFeed posts, news, memes, quizzes and videos.`,
    },
    {
        key: 'Complex',
        favicon: 'https://images.complex.com/complex/image/upload/v1403733167/favicon.ico',
        url: 'https://www.complex.com/bio/',
        title: `Biofeed by Complex`,
        description: `Explore your favorite Complex posts from Instagram and TikTok.`,
        header: `Click to explore your favorite Complex posts, news and videos.`,
    },
    {
        key: 'HuffPost',
        favicon: 'https://www.huffpost.com/static-assets/cambria/app/favicon.ico',
        url: 'https://www.huffpost.com/bio/',
        title: `Biofeed by HuffPost`,
        description: `Explore your favorite HuffPost posts from Instagram and TikTok.`,
        header: `Click to explore your favorite HuffPost posts, news and videos.`,
    },
    {
        key: 'PubHub',
        favicon: 'http://www.buzzfeed.com/static-assets/img/favicon.5a0c77a8815cfcc67c710199054a55c6.ico',
        url: 'https://stage.buzzfeed.bio',
        title: `Biofeed by PubHub`,
        description: `Explore your favorite PubHub posts from Instagram and TikTok.`,
        header: `Click to explore your favorite PubHub posts, news and videos.`,
    },
    {
        key: 'Loading',
        favicon: '',
        url: 'https://buzzfeed.bio',
        title: `Loading`,
        description: ``,
        header: ``,
    },
];

export default brands;