import Footer from '../components/footer';
import Loader from '../components/loader/loader';
import Head from 'next/head';
import { main } from './page.module.scss';
import styles from './index.module.scss';
import Link from 'next/link';
import { useAddressabilityTracking } from '../hooks/analytics/useAddressabilityTracking';
import { usePageviewTracking } from '../hooks/analytics/usePageviewTracking';
import { getBrandProperties } from '../utils/brand-properties';
import { BuzzfeedLogo, ComplexLogo, HuffPostLogo, PubHubLogo } from '../svg';

function HomePage(props) {
    const origin =
        typeof window !== 'undefined' && window.location.origin
            ? window.location.origin
            : '';
    useAddressabilityTracking({id: '0'});
    usePageviewTracking({id: '0'});
    const { key: brand, url, title, description, header, favicon } = getBrandProperties(origin);
    const channels = props.channels || [];
    const channelIds = Object.keys(channels).includes(brand) ? channels[brand].map(item => item.id) : [];
    const accounts = props.accounts || [];
    const channelFilter = ({ pubhub_channel_id }) => {
        return (brand === 'BuzzFeed') || channelIds.includes(pubhub_channel_id);
    };

    const Logo = () => {
        if (brand === 'Complex') {
            return <ComplexLogo className={styles['complex-logo']}/>;
        } else if (brand === 'HuffPost') {
            return <HuffPostLogo className={styles['huffpost-logo']}/>;
        } else if (brand === 'PubHub') {
            return  <PubHubLogo className={styles['pubhub-logo']}/>;
        } else if (brand === "BuzzFeed") {
            return  <BuzzfeedLogo className={styles['bigred-logo']}/>;
        } else {
            return <Loader/>;
        }
    }

    return (
        <div className={styles['page-bg']}>
            <Head>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Biofeed" />
                <meta property="og:description" content={description} />
                <meta property="og:image" content=""/>
                <link rel="shortcut icon" crossOrigin="" href={favicon} type="image/x-icon"/>
            </Head>
            <header className={styles['header']}>
                <Logo/>
                <p>{header}</p>
            </header>
            <main className={main}>
                {accounts.filter(channelFilter).map((account, i) =>
                    <Link
                        key={i}
                        // eslint-disable-next-line max-len
                        href={`/bio/${account['platform'] === 'tiktok' ? 'tt' : 'ig'}/${account['name']}`}
                        passHref
                    >
                        <div className={styles['grid-item']}>
                            <div className={`${styles['grid-content']} ${styles['account']}`}>
                                <img
                                    src={account['icon_image_url']}
                                    alt={`${account['name']} icon`}
                                />
                            </div>
                        </div>
                    </Link>
                )}
            </main>
            <Footer brand={brand}/>
        </div>
    );
}

export async function getStaticProps() {
    let accounts = [];
    let channels = {};
    if (process.env['CLUSTER']) {
        const { getAccounts } = require('../api/biofeed_api');
        accounts = await getAccounts();
        //
        const { getChannelsByBrand } = require('../api/pubhub_api');
        channels['Complex'] = await getChannelsByBrand('Complex');
        channels['HuffPost']= await getChannelsByBrand('HuffPost');
        channels['PubHub'] = await getChannelsByBrand('PubHub');
    }
    return {
        props: {
            accounts,
            channels,
        },
        revalidate: (60 * 60 * 24), // 24 hrs
    };
}

export default HomePage;
