import { isServer } from "./misc-utils";
import { queryStringToObject } from '@buzzfeed/bf-utils/lib/query-string';
import brands from '../fixtures/brands';

const queryParams = () => isServer() ? '' : window.location.search;

export const getBrandProperties = (cname = '') => {
    const brandQuery = queryStringToObject(queryParams())['brand'];
    let brand = 'Loading';
    if (cname.includes('complex') || brandQuery === 'complex') {
        brand = 'Complex';
    } else if (cname.includes('huffpost') || brandQuery === 'huffpost') {
        brand = 'HuffPost';
    } else if (cname.includes('pubhub') || brandQuery === 'pubhub') {
        brand = 'PubHub';
    } else if (cname.includes('buzzfeed') || brandQuery === 'buzzfeed') {
        brand = 'BuzzFeed';
    }
    return brands.find(({ key }) => key === brand);
}
