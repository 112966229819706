/* eslint-disable max-len */
export function BuzzfeedLogo(props) {
    return (
        <svg
            {...props}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 315.7 53.2">
            <g>
                <path d="M31.8,27.3c2.5,0.2,4.6,1.2,6.4,3.1c1.8,1.9,2.7,4.5,2.7,7.8c0,4-1.3,7.3-4,9.7c-2.7,2.4-6.5,3.6-11.6,3.6H0.7
                  V4.1h24.5c4.3,0,7.8,1.2,10.4,3.5c2.6,2.4,3.9,5.5,3.9,9.4c0,3-0.8,5.3-2.5,7.1s-3.4,2.7-5.3,2.9L31.8,27.3L31.8,27.3z M12.4,23.7
                  h10.2c1.7,0,3.1-0.5,4.2-1.5c1-1,1.6-2.2,1.6-3.9c0-1.5-0.5-2.6-1.5-3.5s-2.3-1.3-3.8-1.3H12.4V23.7z M23.2,42.1
                  c1.8,0,3.3-0.5,4.3-1.4c1-0.9,1.5-2.2,1.5-3.9c0-1.6-0.5-2.8-1.6-3.7c-1-0.9-2.4-1.4-4-1.4H12.4v10.4
                  C12.4,42.1,23.2,42.1,23.2,42.1z"/>
                <path d="M81.4,51.5H70.1v-3.4c-2.8,3-6.5,4.5-10.9,4.5c-4.2,0-7.5-1.4-10-4.1c-2.5-2.7-3.8-6.3-3.8-10.7V16h11.2v19.4
                  c0,2.1,0.6,3.8,1.7,5.1c1.1,1.3,2.6,1.9,4.4,1.9c2.3,0,4.1-0.8,5.4-2.4c1.3-1.6,1.9-4.1,1.9-7.5V16h11.3L81.4,51.5L81.4,51.5z"/>
                <path d="M116.3,51.5H86.3v-2.1L100.4,25H86.7v-9h29.4v2.1L102,42.5h14.2V51.5z"/>
                <path d="M151.1,51.5h-29.9v-2.1L135.2,25h-13.7v-9h29.4v2.1l-14.1,24.4h14.2L151.1,51.5L151.1,51.5z"/>
                <path d="M167.9,24.4h20.5v10.2h-20.5v17h-11.7V4.1h34v10.2h-22.3V24.4z"/>
                <path d="M271.3,36.7h-26c0.7,4.4,4,6.9,8.9,6.9c3.9,0,7.8-1.5,10.7-3.8l4.3,7.5c-4,3.4-9.1,5.3-15,5.3
                  c-11.9,0-20-7.2-20-18.8c0-5.5,1.8-10,5.4-13.5c3.6-3.5,8.1-5.3,13.4-5.3c5.1,0,9.4,1.7,12.9,5.2s5.3,8,5.4,13.7L271.3,36.7
                  L271.3,36.7z M248.4,25.6c-1.4,1-2.4,2.4-2.9,4.1h14.6c-0.5-1.8-1.4-3.2-2.7-4.2c-1.3-0.9-2.8-1.4-4.4-1.4
                  C251.3,24.1,249.8,24.6,248.4,25.6z"/>
                <path d="M229.9,36.7h-26c0.7,4.4,4,6.9,8.9,6.9c3.9,0,7.8-1.5,10.7-3.8l4.3,7.5c-4,3.4-9.1,5.3-15,5.3
                  c-11.9,0-20-7.2-20-18.8c0-5.5,1.8-10,5.4-13.5c3.6-3.5,8.1-5.3,13.4-5.3c5.1,0,9.4,1.7,12.9,5.2s5.3,8,5.4,13.7L229.9,36.7
                  L229.9,36.7z M207,25.6c-1.4,1-2.4,2.4-2.9,4.1h14.6c-0.5-1.8-1.4-3.2-2.7-4.2c-1.3-0.9-2.8-1.4-4.4-1.4
                  C209.9,24.1,208.4,24.6,207,25.6z"/>
                <path d="M315,51.5h-11.3v-2.8c-2.9,2.6-6.5,3.9-11,3.9c-4.8,0-8.9-1.8-12.3-5.3c-3.4-3.5-5.1-8-5.1-13.5
                  c0-5.5,1.7-10,5.1-13.5s7.5-5.3,12.3-5.3c4.5,0,8.2,1.3,11,3.9V0.6H315V51.5z M301.2,40.4c1.7-1.7,2.6-3.9,2.6-6.6
                  c0-2.6-0.9-4.8-2.6-6.6c-1.7-1.7-3.8-2.6-6.1-2.6c-2.5,0-4.5,0.9-6.1,2.6s-2.4,3.9-2.4,6.6c0,2.7,0.8,5,2.4,6.7s3.6,2.5,6.1,2.5
                  C297.4,43,299.4,42.1,301.2,40.4z"/>
            </g>
        </svg>
    );
}

export function ComplexLogo(props) {
    return (
        <svg {...props} x="0px" y="0px" viewBox="0 0 1941.731 500">
            <path fillRule="evenodd" clipRule="evenodd" d="M261.672 495.797H108.158C37.355 495.797 0 443.262 0 363.302V136.918C0 56.994 16.952 0 119.31 0h39.12c72.23 0 103.748 57.073 103.748 137.705v44.548H144.4v-72.09c0-6.313-5.323-12.02-11.636-12.02h-1.446c-6.313 0-12.143 5.707-12.143 12.02v271.41c0 7.72 6.563 12.572 12.877 12.572H260.13l1.542 101.652zM929.41 5.087v490.71H811.635v-221.82l-26.988 221.82h-79.024l-28.976-221.82v221.82H559.27l-.395-490.71H714.89l29.25 221.96 28.937-221.96H929.41zm297.06 0h119.176v389.058h82.018v101.65h-201.192V5.085zm713.855 0l-63.078 236.938 64.484 253.77h-123.367l-22.43-126.182-25.947 126.183h-107.26l64.502-236.95-63.095-253.76h123.377l20.328 124.077 25.234-124.077h107.253zM393.45 112.457v275.13c0 7.43 6.028 13.45 14.11 13.45 7.43 0 13.447-6.022 13.447-13.452v-275.13c0-7.43-6.02-13.46-14.1-13.46-7.428 0-13.456 6.03-13.456 13.46zM540.583 394.85c0 70.798-34.357 105.15-105.164 105.15h-56.084c-70.798 0-105.146-34.353-105.146-105.15V106.03C274.19 35.227 308.536.88 379.334.88h56.083c70.807 0 105.164 34.348 105.164 105.15v288.82zm527.534-295.168v116.22l12.362.063c6.83 0 12.36-6.014 12.36-13.448V113.13c0-7.425-5.53-13.448-12.36-13.448h-12.363zm.405 204.85v191.265H949.35V5.087h155.625c70.798 0 105.15 34.347 105.15 105.145v89.153c0 70.802-34.353 105.146-105.15 105.146h-36.454zM1441.038 3.74h210.556v101.77h-91.24v89.83h87.74v101.77h-87.74v96.146H1653V495.03h-211.963V3.742z"/>
        </svg>
    );
}

export function HuffPostLogo(props) {
    return (
        <svg {...props} width="203.22823mm" height="23.763111mm"  viewBox="0 0 720.10001 84.2">
            <g transform="translate(37.193 -635.98)">
                <g transform="translate(-68.571 182.86)">
                    <path d="m124.48 535.62h26.3l14.1-80.7h-26.3l-5.2 28.9h-22.8l5.2-28.9h-26.3l-14.1 80.7h26.3l5.3-30.2h22.8l-5.3 30.2z"/>
                    <path d="m179.78 454.82-8 44.9c-0.7 3.5-1 6.9-1 10.1 0 23.8 20.8 27.4 33.3 27.4 26.2 0 37.5-8.7 41.6-32.2l8.8-50.3h-26.3l-7.5 42.2c-2.4 13.2-4.1 20.1-13.9 20.1-6.4 0-9.5-3.4-9.5-10.4 0-2.7 0.4-6 1.2-10.1l7.6-41.8h-26.3z"/>
                    <path d="m393.48 535.62h26.3l3.7-20.8h12.4c22.9 0 35.6-12.4 35.6-35 0-15.9-11-25-30.3-25h-33.6l-14.1 80.8zm41-40.7h-7.6l3.5-19h7.1c5.8 0 8.9 2.9 8.9 8.2 0 6.7-4.5 10.8-11.9 10.8z"/>
                    <path d="m522.58 453.12c-28.6 0-45.7 18.5-45.7 49.6 0 21.6 13.6 34.6 36.4 34.6 28.6 0 45.7-18.5 45.7-49.6 0.1-21.7-13.5-34.6-36.4-34.6zm-7.7 63.3c-6.8 0-10.8-4.9-10.8-13.1 0-3.1 0.3-5.8 0.9-8.9 2-10.6 5.4-20.3 16.1-20.3 6.8 0 10.8 4.9 10.8 13.1 0 3.1-0.3 5.8-0.9 8.9-2 10.5-5.4 20.3-16.1 20.3z"/>
                    <path d="m650.48 535.62h26.3l10.4-59.1h19.8l3.8-21.6h-67.3l-3.8 21.6h21l-10.2 59.1z"/>
                    <path d="m606.88 484.72c-8.3-2.6-11.7-3.7-11.7-7.6 0-2.6 1.7-5.7 6.7-5.7 3.7 0 6.8 2.1 8 5.2l23.2-6.3c-2.7-11.4-12.5-17.2-29.4-17.2-31.8 0-34.3 21.3-34.3 27.8 0 13.2 7 21.3 22.2 25.5 4 1.1 8.6 2.3 8.6 6.7 0 3.5-2.6 5.7-7.1 5.7-4.1 0-8.5-2.4-10-6.6l-22.9 6.2c2.5 12 13.8 18.8 31.5 18.8 13.5 0 36.1-3.6 36.1-28 0.1-12.2-6.8-20.2-20.9-24.5z"/>
                    <path d="m60.679 535.62 14.2-80.7h-43.5v80.7h29.3z" fill="#0dbe98"/>
                    <path d="m722.08 454.92-14.1 80.7h43.5v-80.7h-29.4z" fill="#0dbe98"/>
                    <polygon transform="translate(31.379 450.22)" points="254.5 61.6 276.6 61.6 280.2 41.3 258.1 41.3 260.6 26.3 291.5 26.3 295.3 4.6 238.1 4.6 224 85.4 250.3 85.4"/>
                    <polygon transform="translate(31.379 450.22)" points="323.4 61.6 345.6 61.6 349.2 41.3 327.1 41.3 329.6 26.3 360.5 26.3 364.3 4.6 307.1 4.6 293 85.4 319.3 85.4"/>
                </g>
            </g>
        </svg>
    );
}

export function PubHubLogo(props) {
    return (
        <svg {...props} x="0px" y="0px" viewBox="0 0 168 168">
            <path d="M82.636 165.636C37.02 165.636 0 128.616 0 83 0 37.385 37.02.364 82.636.364c45.615 0 82.636 37.02 82.636 82.636 0 45.615-37.022 82.636-82.636 82.636z" fill="#E32"/>
            <path d="M40.5 111.045h4.133c0 6.442 5.29 11.73 11.734 11.73 6.446 0 11.734-5.288 11.734-11.73h32.643c0 6.442 5.29 11.73 11.734 11.73 6.445 0 11.734-5.288 11.734-11.73h6.278c3.553 0 6.198-3.143 5.702-6.693l.083-8.264h-35.45v5.537h-3.8v-11.32H40.5a5.748 5.748 0 0 0-5.784 5.783v9.09c0 3.222 2.645 5.867 5.785 5.867H40.5z" fill="#800"/>
            <path d="M40.5 43.284h56.44v58.34H65.458c0 4.96-4.05 9.008-9.007 9.008s-9.007-4.05-9.007-9.007h-6.86A5.75 5.75 0 0 1 34.8 95.84V49.07c-.084-3.224 2.56-5.786 5.7-5.786zm95.693 51.647l-3.058-20.658c-1.487-10-10-17.354-20.08-17.52l-12.23-.165v44.957h2.727c0 4.958 4.05 9.007 9.007 9.007s9.006-4.048 9.006-9.006h8.925c3.554.08 6.2-3.06 5.703-6.613v-.002z" fill="#FFF"/>
            <path d="M40.5 89.643h56.44v11.982H68.185c0 6.442-5.29 11.73-11.734 11.73-6.446 0-11.734-5.288-11.734-11.73h-4.132A5.75 5.75 0 0 1 34.8 95.84V83.858c-.084 3.222 2.56 5.785 5.7 5.785zm95.693 5.287l-1.157-7.603c-1.074 1.405-2.645 2.314-4.545 2.314h-29.665v11.983c0 6.442 5.29 11.73 11.734 11.73 6.445 0 11.733-5.288 11.733-11.73h6.198c3.554.002 6.2-3.14 5.703-6.692l-.001-.002zM113.8 61.546l-8.182-.166v12.81l22.725.082c-1.074-7.19-7.272-12.56-14.544-12.726h.001zm-63.466 5.206c0-8.594 7.024-15.618 15.618-15.618 8.594 0 15.618 7.024 15.618 15.618 0 8.594-7.024 15.618-15.618 15.618-8.594 0-15.618-7.023-15.618-15.618z" fill="#FF806F"/>
            <path d="M57.524 72.868l2.48 1.403 2.974-5.205 4.462 2.562 4.05-6.94 2.726 1.57-.99-7.273-6.943 2.81 2.727 1.57-2.562 4.463-4.462-2.562-4.462 7.602zM56.45 95.51a6.101 6.101 0 0 0-6.115 6.115 6.097 6.097 0 0 0 6.115 6.11 6.1 6.1 0 0 0 6.115-6.11 6.1 6.1 0 0 0-6.115-6.115zm56.11 0a6.1 6.1 0 0 0-6.116 6.115 6.097 6.097 0 0 0 6.115 6.11 6.1 6.1 0 0 0 6.114-6.11 6.1 6.1 0 0 0-6.115-6.115h.002z" fill="#FFF"/>
        </svg>
    );
}
